<template>
  <div class="login">
    <header-m></header-m>
    <div class="box">
      <div class="photo">
        <img src="../assets/img/avatar.png" class="pic" />
      </div>
      <el-tabs v-model="activeName" @tab-click="handleClick" stretch="false" class="tabs-box">
        <el-tab-pane label="手机快捷登录" name="first">
          <el-form
            class="form-box"
            ref="phoneLoginForm"
            :model="phoneLoginForm"
            :rules="phoneLoginRules"
            label-width="0"
          >
            <el-form-item label="" prop="phone">
              <el-input v-model="phoneLoginForm.phone" placeholder="请输入手机号"></el-input>
            </el-form-item>
            <el-form-item label="" prop="code">
              <el-input
                v-model="phoneLoginForm.code"
                @keyup.enter="phoneLoginSubmit"
                placeholder="请输入验证码"
              >
                <template #append
                  ><el-button :disabled="disabledLogin" @click="sendcodeLogin">{{
                    btntxtLogin
                  }}</el-button></template
                >
              </el-input>
            </el-form-item>
            <!-- <el-form-item label="">
                <el-checkbox
                  label="已阅读并同意"
                  v-model="phoneLoginAgree"
                ></el-checkbox><span class="agree-tip">用户隐私协议和用户服务协议</span>
              </el-form-item> -->
            <el-form-item class="submit-box">
              <el-button class="submit-btn" type="primary" @click="phoneLoginSubmit"
                >立 即 登 录</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
        <el-tab-pane label="账号密码登录" name="second">
          <el-form
            class="form-box"
            ref="passLoginForm"
            :model="passLoginForm"
            :rules="passRules"
            label-width="0"
          >
            <el-form-item label="" prop="username">
              <el-input v-model="passLoginForm.username" placeholder="请输入账号"></el-input>
            </el-form-item>
            <el-form-item label="" prop="password">
              <el-input
                v-model="passLoginForm.password"
                show-password
                @keyup.enter="passLoginSubmit"
                placeholder="请输入密码"
              >
                <template #append
                  ><el-button @click="showPass()">忘记密码</el-button></template
                ></el-input
              >
            </el-form-item>
            <!-- <el-form-item label="">
                <el-checkbox
                  v-model="passLoginAgree"
                  label="已阅读并同意"
                ></el-checkbox><span class="agree-tip">用户隐私协议和用户服务协议</span>
              </el-form-item> -->
            <el-form-item class="submit-box">
              <el-button class="submit-btn" type="primary" @click="passLoginSubmit"
                >立 即 登 录</el-button
              >
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <div class="register-btn" @click="showRegister()">免费注册</div>
    </div>

    <!-- 注册弹窗 -->
    <div id="dialog" v-show="dialogRegister">
      <div class="border">
        <div @click="closeRegisterDialog" class="closeImg">
          <el-icon class="close-icon">
            <close />
          </el-icon>
        </div>
        <h6>注 册 账 号</h6>
        <el-form
          class="dialog-form"
          ref="RegisterForm"
          :model="RegisterForm"
          :rules="RegisterRules"
          label-width="0"
        >
          <el-form-item label="" prop="phone">
            <el-input v-model="RegisterForm.phone" placeholder="请输入手机号"></el-input>
          </el-form-item>
          <el-form-item label="" prop="code">
            <el-input v-model="RegisterForm.code" placeholder="请输入验证码">
              <template #append
                ><el-button :disabled="disabledRegister" @click="sendcodeRegister">{{
                  btntxtRegister
                }}</el-button></template
              >
            </el-input>

            <!-- <el-button
                :disabled="disabledRegister"
                @click="sendcodeRegister"
                >{{ btntxtRegister }}</el-button
              > -->
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input
              v-model="RegisterForm.password"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item label="" class="check-box">
            <el-checkbox label="已阅读并同意" v-model="RegisterAgree"></el-checkbox>
            <a class="agree-tip" href="https://sdk.x8game.net/xbkhxy1.html" target="view_window"
              >用户隐私协议和用户服务协议</a
            >
            <!-- <span class="agree-tip" @click="isShowAgree(true)">用户隐私协议和用户服务协议</span> -->
          </el-form-item>
          <el-form-item class="dialog-submit">
            <el-button type="primary" @click="registerSubmit">快速注册</el-button>
          </el-form-item>
        </el-form>
        <div @click="closeRegisterDialog()" class="tip">已注册, 请登录</div>
      </div>
    </div>

    <!-- 忘记密码 短信重置密码 弹窗 -->
    <div id="dialog" v-show="dialogPass">
      <div class="border">
        <div @click="closePassDialog" class="closeImg">
          <el-icon class="close-icon">
            <close />
          </el-icon>
        </div>
        <h6>短 信 重 置 密 码</h6>
        <el-form
          class="dialog-form"
          ref="ResetPassForm"
          :model="ResetPassForm"
          :rules="ResetPassRules"
          label-width="0"
        >
          <el-form-item label="" prop="username">
            <el-input v-model="ResetPassForm.username" placeholder="请输入用户名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="code">
            <el-input v-model="ResetPassForm.code" placeholder="请输入验证码">
              <template #append>
                <el-button :disabled="disabledResetPass" @click="sendcodeResetPass">{{
                  btntxtResetPass
                }}</el-button></template
              >
            </el-input>

            <!-- <el-button
                :disabled="disabledResetPass"
                @click="sendcodeResetPass"
                >{{ btntxtResetPass }}</el-button
              > -->
          </el-form-item>
          <el-form-item label="" prop="password">
            <el-input
              v-model="ResetPassForm.password"
              show-password
              placeholder="请输入密码"
            ></el-input>
          </el-form-item>
          <el-form-item class="dialog-submit">
            <el-button type="primary" @click="ResetPassSubmit">确定提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 实名认证 绑定身份证 弹窗 -->
    <div id="dialog" v-show="dialogBindcard">
      <div class="border">
        <div @click="closeBindcardDialog" class="closeImg">
          <el-icon class="close-icon">
            <close />
          </el-icon>
        </div>
        <h6>实 名 认 证</h6>
        <el-form
          class="dialog-form"
          ref="BindcardForm"
          :model="BindcardForm"
          :rules="BindcardRules"
          label-width="0"
        >
          <el-form-item label="" prop="realname">
            <el-input v-model="BindcardForm.realname" placeholder="请输入真实姓名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="idcard">
            <el-input v-model="BindcardForm.idcard" placeholder="请输入身份证号码"></el-input>
          </el-form-item>
          <el-form-item class="dialog-submit">
            <el-button type="primary" @click="BindcardSubmit">确认提交</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 协议弹窗 -->
    <div id="dialog" v-show="dialogAgree">
      <div class="agree-box">
        <div @click="isShowAgree(false)" class="closeImg">
          <el-icon class="close-icon">
            <close />
          </el-icon>
        </div>
        <h6>用户隐私协议和用户服务协议</h6>
        <div class="des">协议内容</div>
      </div>
    </div>
  </div>
</template>

<script>
// import '@/assets/js/flexible'
import axios from "@/utils/axios";
import { isMobile } from '@/utils/util'
import { ElMessage } from "element-plus";
import { mapActions, mapState } from "vuex"; //注册 action 和 state
import { Close } from "@element-plus/icons";
import HeaderM from '../components/HeaderM.vue';
// import rulesConst from '@/utils/constant'   // 全局常量
export default {
  name: "LoginM",
  components: { Close,HeaderM },
  data() {
    return {
      disabledLogin: false, // 快速登录发送验证码
      timeLogin: 60,
      btntxtLogin: "发送验证码",
      activeName: "first",
      phoneLoginForm: {
        phone: "",
        code: "",
        imei: "h5",
        device: "web",
        game_id: 1,
        client_id: 1,
        os: 3
      }, // 手机号验证码登录
      phoneLoginRules: {
        phone: {
          required: true,
          message: "请输入正确的手机号",
          trigger: "blur",
          pattern: /^0?(13|14|15|17|18|19)[0-9]{9}$/
        },
        code: {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
          pattern: /^\d{4}|\d{6}$/
        }
      },
      phoneLoginAgree: true,
      passLoginForm: {
        username: "",
        password: "",
        imei: "h5",
        device: "web",
        game_id: 1,
        client_id: 1,
        os: 3
      }, // 账号密码登录
      passRules: {
        username: {
          required: true,
          message: "请输入账号",
          trigger: "blur",
          pattern: /^[a-zA-Z0-9_-]{4,16}$/
        },
        password: {
          required: true,
          message: "请输以字母开头8到16位入密码",
          trigger: "blur",
          pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{6,16}$/
        }
      },
      passLoginAgree: true,
      disabledRegister: false, // 注册发送验证码
      timeRegister: 60,
      btntxtRegister: "发送验证码",
      RegisterForm: {
        phone: "",
        code: "",
        password: "",
        imei: "h5",
        device: "web",
        game_id: 1,
        client_id: 1,
        os: 3
      },
      RegisterRules: {
        phone: {
          required: true,
          message: "请输入正确的手机号",
          trigger: "blur",
          pattern: /^0?(13|14|15|17|18|19)[0-9]{9}$/
        },
        code: {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
          pattern: /^\d{4}|\d{6}$/
        },
        password: {
          required: true,
          message: "请输以字母开头8到16位入密码",
          trigger: "blur",
          pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{6,16}$/
        }
      },
      RegisterAgree: false,
      dialogRegister: false, // 注册弹窗
      dialogPass: false, // 忘记密码 短信重置密码
      disabledResetPass: false, // 通过用户名获取验证码
      timeResetPass: 60,
      btntxtResetPass: "发送验证码",
      ResetPassForm: {
        username: "",
        code: "",
        password: ""
      },
      ResetPassRules: {
        username: {
          required: true,
          message: "请输入账号",
          trigger: "blur",
          pattern: /^[a-zA-Z0-9_-]{4,16}$/
        },
        code: {
          required: true,
          message: "请输入验证码",
          trigger: "blur",
          pattern: /^\d{4}|\d{6}$/
        },
        password: {
          required: true,
          message: "请输以字母开头8到16位入密码",
          trigger: "blur",
          pattern: /^(?=.*?[a-zA-Z])(?=.*?[0-9])[a-zA-Z0-9]{6,16}$/
        }
      },
      LoginRes: {}, // 登录成功后的返回信息
      provingState: 0, // 实名状态（0:未验证，1:真实身份，2:虚假身份，3:正在验证中）
      dialogBindcard: false,
      BindcardForm: {
        username: "",
        realname: "",
        idcard: "",
        token: ""
      },
      BindcardRules: {
        realname: {
          required: true,
          message: "请输入真实姓名",
          trigger: "blur",
          pattern: /^([\u4e00-\u9fa5]{2,20}|[a-zA-Z.\s]{2,20})$/
        },
        idcard: {
          required: true,
          message: "请输入正确的身份证号码",
          trigger: "blur",
          pattern: /^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/
        }
      },
      dialogAgree: false // 是否显示协议
    };
  },
  created() {},
  mounted() {
    if (isMobile()) {
      // alert("手机端");
      // this.$router.replace('/loginm');
    } else {
      // alert("pc端");
      this.$router.replace('/login');
    }
  },
  methods: {
    // 快速登录发送验证码
    sendcodeLogin() {
      console.log("点击发送验证码啦");
      if (/^0?(13|14|15|17|18|19)[0-9]{9}$/.test(this.phoneLoginForm.phone)) {
        axios.post("/phone_code", { phone: this.phoneLoginForm.phone }).then(res => {
          if (res.code == 1) {
            this.timeLogin = 60;
            this.timeFunLogin();
            ElMessage({
              message: res.msg,
              type: "success"
            });
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: "error"
            });
          }
        });
      } else {
        ElMessage({
          message: "请输入正确的手机号获取验证码",
          type: "warning"
        });
      }
    },
    //登录加注册 发送手机验证码倒计时
    timeFunLogin() {
      if (this.timeLogin > 0) {
        this.disabledLogin = true;
        this.timeLogin--;
        this.btntxtLogin = this.timeLogin + "秒";
        setTimeout(this.timeFunLogin, 1000);
      } else {
        this.timeLogin = 0;
        this.btntxtLogin = "发送验证码";
        this.disabledLogin = false;
      }
    },
    // 注册发送验证码
    sendcodeRegister() {
      if (/^0?(13|14|15|17|18|19)[0-9]{9}$/.test(this.RegisterForm.phone)) {
        axios.post("/phone_code", { phone: this.RegisterForm.phone }).then(res => {
          if (res.code == 1) {
            this.timeRegister = 60;
            this.timeFunRegister();
            ElMessage({
              message: res.msg,
              type: "success"
            });
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: "error"
            });
          }
        });
      } else {
        ElMessage({
          message: "请输入正确的手机号获取验证码",
          type: "warning"
        });
      }
    },
    //注册 发送手机验证码倒计时
    timeFunRegister() {
      if (this.timeRegister > 0) {
        this.disabledRegister = true;
        this.timeRegister--;
        this.btntxtRegister = this.timeRegister + "秒";
        setTimeout(this.timeFunRegister, 1000);
      } else {
        this.timeRegister = 0;
        this.btntxtRegister = "发送验证码";
        this.disabledRegister = false;
      }
    },
    // 通过用户名获取验证码
    sendcodeResetPass() {
      if (/^[a-zA-Z0-9_-]{4,16}$/.test(this.ResetPassForm.username)) {
        axios.post("/user_code", { username: this.ResetPassForm.username }).then(res => {
          if (res.code == 1) {
            this.timeResetPass = 60;
            this.timeFunResetPass();
            ElMessage({
              message: res.msg,
              type: "success"
            });
          } else if (res.code == 0) {
            ElMessage({
              message: res.msg,
              type: "error"
            });
          }
        });
      } else {
        ElMessage({
          message: "请输入4到16位账号",
          type: "warning"
        });
      }
    },
    //忘记密码 找回密码 用户名 发送手机验证码倒计时
    timeFunResetPass() {
      if (this.timeResetPass > 0) {
        this.disabledResetPass = true;
        this.timeResetPass--;
        this.btntxtResetPass = this.timeResetPass + "秒";
        setTimeout(this.timeFunResetPass, 1000);
      } else {
        this.timeResetPass = 0;
        this.btntxtResetPass = "发送验证码";
        this.disabledResetPass = false;
      }
    },
    // 手机号登录
    phoneLoginSubmit() {
      if (!this.phoneLoginAgree) {
        ElMessage({
          message: "请勾选协议",
          type: "warning"
        });
        return;
      }
      this.$refs.phoneLoginForm.validate(valid => {
        if (valid) {
          axios.post("/phone_login", this.phoneLoginForm).then(res => {
            if (res.code == 1) {
              this.LoginRes = res;
              window.sessionStorage.setItem("token", JSON.stringify(res.token));
              this.getUserInfo();
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 账号密码登录
    passLoginSubmit() {
      if (!this.passLoginAgree) {
        ElMessage({
          message: "请勾选协议",
          type: "warning"
        });
        return;
      }
      this.$refs.passLoginForm.validate(valid => {
        if (valid) {
          console.log("验证通过");
          axios.post("/user_login", this.passLoginForm).then(res => {
            console.log("res----->存用户信息", res);
            // ElMessage('返回信息'+res)
            if (res.code == 1) {
              this.LoginRes = res;
              window.sessionStorage.setItem("token", JSON.stringify(res.token));
              this.getUserInfo();
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 登录成功后 查看个人信息 判断是否绑定过身份证号
    getUserInfo() {
      axios.post("/user_info", this.LoginRes).then(res => {
        if (res.code == 1) {
          this.provingState = res.msg.proving_state;
          if (this.provingState === 1) {
            window.sessionStorage.setItem("userinfo", JSON.stringify(res.msg));
            this.$store.commit("changeUserType", this.LoginRes.username);
            this.$store.commit("changeLoginStatus", true);
            this.$router.push({ name: "MycenterM" });
          } else if (this.provingState === 0) {
            // 弹出实名认证弹出
            this.showBindcard();
          } else {
            ElMessage({
              message: "身份证实名认证正在审核中, 请稍后登录",
              type: "warning"
            });
          }
        }
      });
    },
    // 绑定身份证号提交
    BindcardSubmit() {
      this.$refs.BindcardForm.validate(valid => {
        if (valid) {
          console.log("验证通过");
          this.BindcardForm.username = this.LoginRes.username;
          this.BindcardForm.token = this.LoginRes.token;
          axios.post("/bindcard", this.BindcardForm).then(res => {
            console.log("res----->存用户信息", res);
            // ElMessage('返回信息'+res)
            if (res.code == 1) {
              ElMessage({
                message: "实名认证成功",
                type: "success"
              });
              window.sessionStorage.setItem("userinfo", JSON.stringify(res.msg));
              this.$store.commit("changeUserType", this.LoginRes.username);
              this.$store.commit("changeLoginStatus", true);
              this.$router.push({ name: "MycenterM" });
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error"
              });
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 快速注册提交
    registerSubmit() {
      if (!this.RegisterAgree) {
        ElMessage({
          message: "请勾选协议",
          type: "warning"
        });
        return;
      }
      this.$refs.RegisterForm.validate(valid => {
        if (valid) {
          axios.post("/phone_login", this.RegisterForm).then(res => {
            if (res.code == 1) {
              ElMessage({
                message: "注册成功, 可以登录啦",
                type: "success"
              });
              // window.sessionStorage.setItem(
              //   "username",
              //   JSON.stringify(res.username)
              // );
              // window.sessionStorage.setItem("token", JSON.stringify(res.token));
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error"
              });
            }
            this.closeRegisterDialog();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    showRegister() {
      this.dialogRegister = true;
    },
    closeRegisterDialog() {
      this.dialogRegister = false;
    },
    showPass() {
      this.dialogPass = true;
    },
    closePassDialog() {
      this.dialogPass = false;
    },
    showBindcard() {
      this.dialogBindcard = true;
    },
    closeBindcardDialog() {
      this.dialogBindcard = false;
    },
    // 短信重置密码提交
    ResetPassSubmit() {
      this.$refs.ResetPassForm.validate(valid => {
        if (valid) {
          axios.post("/code_reset", this.ResetPassForm).then(res => {
            if (res.code == 1) {
              ElMessage({
                message: "重置密码成功, 可以登录啦",
                type: "success"
              });
              // window.sessionStorage.setItem(
              //   "username",
              //   JSON.stringify(res.username)
              // );
              // window.sessionStorage.setItem("token", JSON.stringify(res.token));
            } else if (res.code == 0) {
              ElMessage({
                message: res.msg,
                type: "error"
              });
            }
            this.closePassDialog();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    isShowAgree(type) {
      this.dialogAgree = type;
    }
  }
};
</script>
<style lang="scss">
#dialog {
  .border {
    .check-box {
      .el-form-item__content {
        line-height: 20px;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.login {
  width: 100%;
  .box {
    padding-top: 50px;
    .photo {
      width: 100px;
      height: 200px;
      margin: 0 auto;
      .pic {
        display: block;
        width: 100%;
        padding-top: 60px;
      }
    }
    .tabs-box {
      width: 66%;
      margin: 0 auto;
      .form-box {
        .submit-box {
          .submit-btn {
            width: 100%;
            line-height: 1.5;
            font-size: 20px;
            border: 0;
            // background: url(https://youzupt.oss-cn-shanghai.aliyuncs.com/uxin-node/5f1942142c4ff_155356.png)
            //   no-repeat center center / 100% 100%;
          }
        }
      }
    }
    .register-btn {
      color: #999;
      margin-top: 60px;
      cursor: pointer;
      font-size: 14px;
    }
  }
  .agree-tip {
    color: #b15200;
    cursor: pointer;
  }
  .border {
    h6 {
      font-weight: normal;
      font-size: 20px;
      color: #555;
      line-height: 80px;
      margin: 0;
    }
    .dialog-form {
      width: 90%;
      margin: 0 auto;
      .check-box {
        text-align: left;
      }
      .dialog-submit {
        button {
          width: 100%;
          line-height: 30px;
          font-size: 18px;
        }
      }
    }
    .tip {
      color: #999;
      margin-top: 50px;
      cursor: pointer;
    }
  }
}
#dialog {
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);
  width: 100%;
  height: 100%;
  z-index: 200;
  .border {
    text-align: center;
    background-color: white;
    border-radius: 20px;
    width: 90%;
    height: 70%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    overflow: hidden;

    .closeImg {
      width: 20px;
      height: 20px;
      float: right;
      margin-right: 20px;
      margin-top: 10px;
      cursor: pointer;
      background-size: cover;
      background-repeat: no-repeat;
    }
    i {
      svg {
        width: 26px;
        color: #999;
      }
    }
  }
  .agree-box {
    text-align: center;
    background-color: white;
    width: 80%;
    height: 70%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 16px;
    overflow-y: scroll;
    h6 {
      padding-top: 30px;
    }
    .des {
      padding: 30px;
    }
    .closeImg {
      width: 20px;
      height: 20px;
      float: right;
      margin-right: 20px;
      margin-top: 10px;
      cursor: pointer;
      background-size: cover;
      background-repeat: no-repeat;
    }
    i {
      svg {
        width: 26px;
        color: #999;
      }
    }
  }
}
.message {
  text-align: center;
  position: absolute;
  top: 50%;
}
</style>
